<template>
  <v-row no-gutters>
    <v-col class="font-weight-bold mt-5 mb-1 mr-12">{{ result.fields.c_space.name }}</v-col>
      <v-col id="setup_switch_container" class="ml-5 mr-4" >
        <v-switch
          dense
          v-model="setup"
          hide-details
          class="d-inline-block"
        >
          <template v-slot:label>
            <span style="width: max-content;" class="pr-2">{{ language.componentLabel.labelSetup }}</span>
            <span class="currentPre">{{ result.spacesInfo.currentPre }}</span
            >/
            <span class="totalPre">{{ result.spacesInfo["totalPre"] }}</span>
          </template>
        </v-switch>
      </v-col>
      <v-col id="testSteps_switch_container" class="mr-4">
        <v-switch
              v-model="testSteps"
              dense
              hide-details
              class="d-inline-block"
              disabled="disabled"
      >
          <template v-slot:label>
              <span style="width: max-content;"  class="pr-2">{{ language.componentLabel.labelTestSteps }}</span>
              <span class="currentTestSteps">({{totalTestSteps}})</span>
          </template>
      </v-switch>
      </v-col>
      <v-col id="clearing_switch_container" class="mr-5">
        <v-switch
          dense
          v-model="clearing"
          hide-details
          class="d-inline-block"
        >
          <template v-slot:label>
            <span style="width: max-content;"  class="pr-2">{{
              language.componentLabel.labelClearing
            }}</span>
            <span class="currentPost">{{ result.spacesInfo.currentPost }}</span
            >/
            <span class="totalPost">{{ result.spacesInfo["totalPost"] }}</span>
          </template>
        </v-switch>
      </v-col>
      <v-col id="info_switch_container">
        <v-switch
                dense
                v-model="info"
                hide-details
                class="d-inline-block"
        >
          <template v-slot:label>
            <span style="width: max-content;"  class="pr-2">{{ language.componentLabel.labelInfo }}</span
            >(
            <span class="currentInfo" id="infoCount">{{ result.spacesInfo.currentInfo }}</span
            >)
          </template>
        </v-switch>
      </v-col>
   
  </v-row>
</template>
<script>
//import fieldName from "@/commonComponents/fieldName.vue";

export default {
  components: {
    // fieldName
  },
  props: ["result", "fieldAttributes", "value"],
  data() {
    return {
      // setup: false,
      // clearing: false,
      // info: false,
      testSteps: true,
      control: {
        pre: false,
        post: false,
        condition: true,
        //condition: (this.result.spacesInfo.currentInfo!=0)? true:false,
      },
    };
  },
  created() {
  //  this.$parent.$root.getFilteredRow(this.control, this);
    //this.$set(this.result, "control", this.control);
    //this.$store.commit("resultChanged", this.result);
  },
  computed: {
    totalTestSteps(){
       let total = 0;
       if(typeof this.result.spacesInfo["testStepsCount"]!=='undefined'){
           total = this.result.spacesInfo["testStepsCount"];
       }
       return total;
    },
    setup: {
      get() {
        return this.control.pre;
      },
      set(newValue) {
        // this.control["pre"] = newValue;
        this.$set(this.control, "pre", newValue);
        //this.getFilteredRow();
        this.$parent.$root.getFilteredRow(this.control, this);
        //this.$set(this.result, "control", this.control);
        //this.$store.commit("resultChanged", this.result);
      },
    },
    clearing: {
      get() {
        return this.control.post;
      },
      set(newValue) {
        // this.control["post"] = newValue;
        this.$set(this.control, "post", newValue);
        //this.getFilteredRow();
        this.$parent.$root.getFilteredRow(this.control, this);
        //this.$set(this.result, "control", this.control);
        //this.$store.commit("resultChanged", this.result);
      },
    },
    info: {
      get() {
        return this.control.condition;
      },
      set(newValue) {
          console.log(newValue);
        //this.control["condition"] = newValue;
        this.$set(this.control, "condition", newValue);
        //this.getFilteredRow();
        this.$parent.$root.getFilteredRow(this.control, this);
        //this.$set(this.result, "control", this.control);
        //this.$store.commit("resultChanged", this.result);
      },
    },

    /*  control() {
      return this.value;
    },*/
  },
  methods: {
    /*    getFilteredRow() {
      this.$emit("input", this.control);
      var excludeRows = []; //need to know
      let caller = this;
      this.frameworkAxiosRequest({
        method: "POST",
        url: "serve.php?f=testing&f2=testInfo&function=getInfoUpdate",
        dataType: "json",
        data: {
          n_id: this.$route.query.n_id,
          n_id_key: this.$route.query.n_id_key,
          requestType: "ajax",
          excludeRows: excludeRows,
          pre: this.control.pre,
          post: this.control.post,
          condition: this.control.condition,
        },
      })
        .then(function (response) {
          // console.log(response.data.result.table);
          caller.$store.commit("tableChanged", response.data.result.table);
        })
        .catch(function (response) {
          console.log(response);
        });
    },*/
  },
};
window.updateInProgress = false;
</script>