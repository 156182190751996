<template>
  <span class="dateInfoContainer">
    <strong class="mr-1">{{language.componentLabel.labelIssued}}:</strong>
    <span class="iTime">{{rowVal["iTime"]}}</span>
    <span class="sTimeArea">
<!--
      <span v-if="(!empty(rowVal['sTime']) && (new Date()).getTime() > rowVal['sTime'] )">
-->
      <span v-if="(!empty(rowVal['sTime']) )">
        |
        <strong class="mr-1">{{language.componentLabel.labelStarted}}:</strong>
        <span  id="startTime" class="sTime">{{rowVal["sTime"]}}</span>
      </span>
    </span>
    <span class="eTimeArea">
      <span v-if="(!empty(rowVal['eTime']))">
        |
        <strong class="mr-1">{{language.componentLabel.labelFinished}}:</strong>
        <span class="eTime">{{rowVal["eTime"]}}</span>
      </span>
    </span>
  </span>
</template>
<script>
export default {
  props: ["fieldAttributes","value"],
  computed: {
    data(){
      return{
      date:new Date()
      };
    },
    rowVal() {
      let rowVal = {};
      if (typeof this.value != "undefined") {
        rowVal = this.value;
      }
      return rowVal;
    },
  },
};
</script>