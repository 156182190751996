<template>
  <div>
    <div  v-if="typeof result!=='undefined' &&
                typeof result.fields['n_status']!=='undefined' &&
                typeof result.fields['n_status']['value']!=='undefined' &&
                typeof result.fields['n_status']['colorMapping']!=='undefined'">
      <label
              class="statusLabel"
              :style=style
      >{{this.result.fields["n_status"].associatedOption[result.fields['n_status']['value']]}}</label>
    </div>
  </div>
</template>
<script>
export default {
  props: ["result"],
  computed: {
    val() {
        let originalVal=0;
        if(typeof this.result!=='undefined' &&
           typeof this.result.fields['n_status']!=='undefined' &&
           typeof this.result.fields['n_status']['fieldAttrInput']!=='undefined'){
          originalVal = this.result.fields["n_status"]["fieldAttrInput"]["data-originalvalue"];
        }
        return originalVal;
    },
    style(){
        let styleVal = [0,0,0];
        if(typeof this.result.fields['n_status']['colorMapping'][this.val]!=='undefined'){
            styleVal= this.result.fields['n_status']['colorMapping'][this.val].join(',');
        }
        return 'color:rgb('+styleVal+')';
    }
  },
};
</script>