<template>
  <div>
    <addField
      v-if="valueLine"
      path="serve.php?f=configuration&f2=subscriber"
      :additionalAttributes="additionalAttributes"
      :dialogAttributes="{}"
      >{{ valueLine.c_subscriber }}</addField
    >
  </div>
</template>
<script>
import addField from "@/commonComponents/addField.vue";
export default {
  components: { addField },

  props: ["valueLine","result"],
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "resultSubmitted") {
        console.log("resultSubmitted",state.resultSubmittedObject[this.result.dialogRef]);
      }
    });
  },
  computed: {
    subscriberName() {
      return this.row[this.header].name;
    },

    additionalAttributes: function () {
      // let additionalAttributes = this.currentTable["editAdditionalAttributes"];
      let additionalAttributes = {
        n_id: this.valueLine.n_subscriberId,
        n_id_key: this.valueLine.n_subscriberId_key,
        function: "getInsertedFieldsEdit",
        //  ...additionalAttributes,
      };
      return additionalAttributes;
    },
  },
};
</script>